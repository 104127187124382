<template>
  <v-card outlined class="ma-2">
    <v-card-title>
      <span class="font-weight-bold">
        <v-icon>mdi-numeric-1-box-multiple-outline</v-icon>
        Parcelas
      </span>
      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="parcelas_headers"
        :items="parcelas_sem_entrega_inicial"
        :items-per-page="-1"
        disable-sort
        fixed-header
        hide-default-footer
        class="data-tables"
      >
        <template v-slot:item.data_vencimento="{ item, index }">
          <div
            class="my-2 d-flex align-center"
            v-if="isEditingVencimento && indexEditingVencimento === index"
          >
            <v-btn class="mr-4" @click="saveEditingVenciamento()" small icon>
              <v-icon color="success">mdi-content-save</v-icon>
            </v-btn>
            <DataField
              :data_sync.sync="dataEditing"
              :data_min="data_hoje"
              :blocked_days="[5, 6]"
            >
            </DataField>
          </div>
          <v-card flat @click="editarVencimento(item, index)" v-else>
            <v-icon small left>mdi-pencil</v-icon>

            <span>
              {{ item.data_vencimento | dateFormat("dd/MM/yyyy") }}
            </span>
          </v-card>
        </template>

        <template v-slot:item.valor="{ item, index }">
          <div v-show="!isEditingValor || index != indexEditingValor">
            <v-card
              flat
              @click="
                parcelas.length - 1 === index ? '' : editarValores(item, index)
              "
            >
              <v-icon
                v-if="parcelas.length - 1 === index ? false : true"
                small
                left
                >mdi-pencil</v-icon
              >
              {{ venda.moeda_sigla }}
              <span v-if="venda.moeda_sigla === 'G$'">
                {{ item.valor | guarani }}
              </span>
              <span v-else>
                {{ item.valor | currency }}
              </span>
            </v-card>
          </div>
          <div v-show="isEditingValor && index == indexEditingValor">
            <div class="my-2 d-flex align-center">
              <v-btn class="mr-4" @click="saveEditingValores()" small icon>
                <v-icon color="success">mdi-content-save</v-icon>
              </v-btn>
              <v-text-field
                v-show="isEditingValor && index == indexEditingValor"
                ref="valorEditing"
                v-model="valorEditing"
                label="Valor"
                dense
                outlined
                hide-details
                :error-messages="erroValor"
                style="width: 1vw"
                class="remove-underline input-right"
                v-currency="vCurrencyOptions"
              ></v-text-field>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  format,
  parseISO,
  addMonths,
  addBusinessDays,
  getDay,
  addDays,
} from "date-fns";
import { getIntervalosParcelas } from "@/api/vendas/vendas.js";
export default {
  name: "TabelaParcelasVendas",

  props: {
    dialogParcelarVendas: {
      type: Boolean,
      default: false,
    },
    venda: {
      type: Object,
    },
    entrega_inicial: {
      type: Number,
    },
    data_entrega_inicial: {
      type: String,
    },
    data_vencimento: {
      type: String,
    },
    numero_parcelas: {
      type: [Number, String],
    },
    intervalo_parcelas: {
      type: Number,
    },
  },

  data() {
    return {
      loading: false,
      parcelas: [],
      isEditingVencimento: false,
      indexEditingVencimento: null,
      dataEditing: null,
      isEditingValor: false,
      indexEditingValor: null,
      valorEditing: 0,
      erroValor: "",
      data_hoje: format(new Date(), "yyyy-MM-dd"),
      valor_invalido: false,
    };
  },

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
  },

  watch: {
    numero_parcelas() {
      if (this.numero_parcelas > 0) {
        this.gerarParcelas();
      }
    },
    valor_sem_entrega_inicial() {
        this.gerarParcelas();
    },
    data_vencimento() {
      this.gerarParcelas();
    },
  },

  computed: {
    valor_sem_entrega_inicial() {
      if (this.entrega_inicial) {
        return this.venda.total - this.entrega_inicial;
      }
      return this.venda.total;
    },
    parcelas_headers() {
      let headers = [
        {
          text: this.$tc("global.data-vencimento"),
          value: "data_vencimento",
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
          width: "200px",
          align: "end",
        },
      ];
      return headers;
    },
    vCurrencyOptions() {
      return {
        precision: this.venda.moeda_sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },
    edit_valor() {
      let result = true;

      if (this.parcelas.length > 1) {
        result = false;
      }

      return result;
    },
    parcelas_sem_entrega_inicial() {
      let result = this.parcelas;
      if (this.entrega_inicial) {
        result = this.parcelas.filter((item) => item.data_entrega_inicial == null);
        return result;
      }
      return result;
    },
  },

  methods: {
    // fetchVarIntervalo() {
    //   getIntervalosParcelas().then((response) => {
    //     let data_intervalo = response;
    //     this.intervalo = Number(data_intervalo);
    //     // this.data_intervalo =  response;
    //   });
    // },
    gerarParcelas() {
      this.parcelas = [];
      this.loading = true;
      let valor_parcela = null;

      let data = this.data_vencimento;
      valor_parcela = Number(this.valor_sem_entrega_inicial) / this.numero_parcelas;
      for (let i = 1; i <= this.numero_parcelas; i++) {
        let parcela = {};

        let verifica_data = getDay(parseISO(data));
        if (verifica_data === 6 || verifica_data === 0) {
          parcela.data_vencimento = format(
            addBusinessDays(parseISO(data), 1),
            "yyyy-MM-dd"
          );
        } else {
          parcela.data_vencimento = format(parseISO(data), "yyyy-MM-dd");
        }

        parcela.valor =
          this.venda.moeda_sigla === "G$"
            ? parseInt(valor_parcela)
            : valor_parcela;

        this.parcelas.push(parcela);
        if (this.intervalo_parcelas < 30 && this.intervalo_parcelas > 0) {
          data = format(
            addDays(parseISO(data), this.intervalo_parcelas),
            "yyyy-MM-dd"
          );
        } else {
          data = format(addMonths(parseISO(data), 1), "yyyy-MM-dd");
        }
      }
      let total = this.parcelas.reduce((accumulator, item) => {
        return accumulator + parseInt(item.valor);
      }, 0);

      if (
        total !== Number(this.valor_sem_entrega_inicial) &&
        this.venda.moeda_sigla === "G$"
      ) {
        let resto = Number(this.valor_sem_entrega_inicial) - total;

        this.parcelas[this.parcelas.length - 1].valor =
          this.parcelas[this.parcelas.length - 1].valor + resto;
      }
      this.gerarParcelaEntregaInicial();
      this.$emit("update:parcelas_venda", this.parcelas);
      this.loading = false;
    },

    async atualizarValores() {
      this.loading = true;
      this.valor_invalido = false;
      this.erroValor = "";

      let valor_parcela = null;
      let valor_parcelas_anteriores = null;
      let parcelas_restantes =
        this.numero_parcelas - (this.indexEditingValor + 1);

      for (let j = 0; j < this.indexEditingValor; j++) {
        valor_parcelas_anteriores += this.parcelas[j].valor;
      }

      valor_parcelas_anteriores =
        valor_parcelas_anteriores + this.$ci.parse(this.valorEditing);

      if (valor_parcelas_anteriores > this.valor_sem_entrega_inicial) {
        this.erroValor =
          this.$tc("global.valor") + " " + this.$tc("global.invalido");
        this.valor_invalido = true;
        this.loading = false;
        return;
      }

      valor_parcela =
        (Number(this.valor_sem_entrega_inicial) - valor_parcelas_anteriores) /
        parcelas_restantes;

      this.parcelas[this.indexEditingValor].valor = this.$ci.parse(
        this.valorEditing
      );
      for (let i = this.indexEditingValor + 1; i < this.parcelas.length; i++) {
        this.parcelas[i].valor =
          this.venda.moeda_sigla === "G$"
            ? parseInt(valor_parcela)
            : valor_parcela;
      }

      let total = this.parcelas.reduce((accumulator, item) => {
        return accumulator + parseInt(item.valor);
      }, 0);

      if (
        total !== Number(this.valor_sem_entrega_inicial) &&
        this.venda.moeda_sigla === "G$"
      ) {
        let resto = Number(this.valor_sem_entrega_inicial) - total;

        this.parcelas[this.parcelas.length - 1].valor =
          this.parcelas[this.parcelas.length - 1].valor + resto;
      }
      this.$emit("update:parcelas_venda", this.parcelas);

      this.loading = false;
    },

    atualizarVencimentos() {
      this.loading = true;

      let data = this.dataEditing;

      for (let i = this.indexEditingVencimento; i < this.parcelas.length; i++) {
        let verifica_data = getDay(parseISO(data));
        if (verifica_data === 6 || verifica_data === 0) {
          this.parcelas[i].data_vencimento = format(
            addBusinessDays(parseISO(data), 1),
            "yyyy-MM-dd"
          );
        } else {
          this.parcelas[i].data_vencimento = format(
            parseISO(data),
            "yyyy-MM-dd"
          );
        }
        if (this.intervalo_parcelas < 30 && this.intervalo_parcelas > 0) {
          data = format(
            addDays(parseISO(data), this.intervalo_parcelas),
            "yyyy-MM-dd"
          );
        } else {
          data = format(addMonths(parseISO(data), 1), "yyyy-MM-dd");
        }
      }

      this.$emit("update:parcelas_venda", this.parcelas);
      this.loading = false;
    },

    editarVencimento(item, index) {
      this.cancelEditValores();
      this.isEditingVencimento = true;
      this.indexEditingVencimento = index;
      this.dataEditing = item.data_vencimento;
    },

    saveEditingVenciamento() {
      if (
        this.dataEditing !== null ||
        this.dataEditing !== undefined ||
        this.dataEditing !== ""
      ) {
        this.atualizarVencimentos();
        this.isEditingVencimento = false;
        this.indexEditingVencimento = null;
        this.dataEditing = null;
        this.$emit("update:parcelas_venda", this.parcelas);
      }
    },
    cancelEditVenciamento() {
      this.isEditingVencimento = false;
      this.indexEditingVencimento = null;
      this.dataEditing = null;
    },

    editarValores(item, index) {
      this.cancelEditVenciamento();
      this.isEditingValor = true;
      this.indexEditingValor = index;
      this.$ci.setValue(this.$refs.valorEditing, item.valor);
    },

    async saveEditingValores() {
      if (
        this.valorEditing !== null ||
        this.valorEditing !== undefined ||
        this.valorEditing !== ""
      ) {
        await this.atualizarValores();
        if (!this.valor_invalido) {
          this.isEditingValor = false;
          this.indexEditingValor = null;
          this.valorEditing = null;
          this.$ci.setValue(this.$refs.valorEditing, 0);
          this.$emit("update:parcelas_venda", this.parcelas);
        }
      }
    },

    cancelEditValores() {
      this.isEditingValor = false;
      this.indexEditingValor = null;
      this.valorEditing = null;
    },

    gerarParcelaEntregaInicial(){
      if (this.entrega_inicial){
        let parcela_entrega_inicial = {
          data_entrega_inicial: this.data_entrega_inicial,
          entrega_inicial: this.entrega_inicial,
          data_vencimento: this.data_vencimento,
        };
        this.$emit("update:parcela_entrega_inicial", parcela_entrega_inicial);
        this.$emit("update:parcelas_venda", this.parcelas);
      }
    }
  },

  async mounted() {
    // await this.fetchVarIntervalo();
    if (this.venda) {
      this.gerarParcelas();
    }
  },
};
</script>

<style lang="scss" scoped>
.remove-underline ::v-deep .v-input__slot::before {
  border-style: none !important;
}
.input-right ::v-deep input {
  text-align: right;
}
</style>
